import React, { Component } from "react";
import './popup.css';

export default class PopUpTwo extends Component {
  handleClick = () => {
   this.props.toggle();
  };

render() {
  return (
   <div className="modal">
     <div className="modal_content">
     <span className="close" onClick={this.handleClick}>×</span>
     <p>Taking each “not like me” statement in turn you should reflect on each by asking questions such as:
     <ul>
  <li>Give examples of real situations where you have demonstrated this behaviour.</li>
  <li>What was the result of adopting this behaviour?</li>
  <li>Describe situations where adopting this behaviour might have been useful for you.</li>
  <li>Does not doing something like this ever create a problem for you?</li>
  <li>Does it have any implications for others, around me?</li>
</ul>
</p>
    </div>
   </div>
  );
 }
}