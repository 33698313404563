import React, {useState, useEffect} from 'react';
import './Deck.css';
import Score from "./deck-components/score";
import { green, blue, orange, red } from './deck-components/statements'
import Cards from './deck-components/Cards';
import Result from './deck-components/Result'


function Deck(props) {

  const [finalAgree, setFinalAgree] = useState([])
  const [finalDisagree, setFinalDisagree] = useState([])
  const [agree, setAgree] = useState([])
  const [disagree, setDisagree] = useState([])
  const [nextButtonStatus, setNextButtonStatus] = useState(false)
  const [id, setId] = useState([])
  const [extroversion, setExtroversion] = useState(0)
  const [introversion, setIntroversion] = useState(0)
  const [sensing, setSensing] = useState(0)
  const [intuition, setIntuition] = useState(0)
  const [thinking, setThinking] = useState(0)
  const [feeling, setFeeling] = useState(0)
  const [judging, setJudging] = useState(0)
  const [perceiving, setPerceiving] = useState(0)
  const [currentPage, setCurrentPage] = useState(green)

  useEffect(() => {
    console.log('counter changed!')
  }, [finalAgree, finalDisagree, nextButtonStatus, currentPage])

  // agreeClick adds the selected statements type, id and statement to the arrays found in the state. It uses concat to create a new array with the new statement added to the old array. It then returns this to the state.
  const agreeClick = (type, statement, id) => {
    if (type === "perceiving" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setPerceiving(perceiving+1)
    } else if (type === "judging" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setJudging(judging+1)
    } else if (type === "introversion" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setIntroversion(introversion+1)
    } else if (type === "extroversion" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setExtroversion(extroversion+1)
    } else if (type === "intuition" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setIntuition(intuition+1)
    } else if (type === "sensing" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setSensing(sensing+1)
    } else if (type === "feeling" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setFeeling(feeling+1)
    } else if (type === "thinking" && agree.length < 5) {
      setAgree(agree.concat(statement))
      setId(id.concat(statement))
      setThinking(thinking+1)
    }
  }
// the disagreeClick works much the same as the same as the previous function but instead checks it against the disagree array. 
  const disagreeClick = (type, statement) => {
    if (type === "perceiving" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "judging" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "introversion" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "extroversion" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "intuition" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "sensing" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "feeling" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    } else if (type === "thinking" && disagree.length < 5) {
      setDisagree(disagree.concat(statement))
    }
  }
  //the unclick function removes the statement from the array when the user taps on the selected card after it has already been selected. It uses the .filter method to find the correct statement. 
  const unclick = (type, statement, id) => {
    if (type === "perceiving") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "judging") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "introversion") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "extroversion") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "intuition") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "sensing") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "feeling") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    } else if (type === "thinking") {
      setAgree(agree.filter(item => item !== statement))
      setDisagree(disagree.filter(item => item !== statement))
      setId(id.filter(item => item !== id))
    }
  }

  // This function adds the selected statements to the total number for the type of statement selected. 
  const nextButton = (id) => {
    let number = 0
    for (let count = 0; count < 5; count++) {
      if (id[number].includes("perceiving")) {
        setPerceiving(perceiving + 1)
      } else if (id[number].includes("judging")) {
        setJudging(judging + 1)
      } else if (id[number].includes("introversion")) {
        setIntroversion(introversion + 1)
      } else if (id[number].includes("extroversion")) {
        setExtroversion(extroversion + 1)
      } else if (id[number].includes("intuition")) {
        setIntuition(intuition + 1)
      } else if (id[number].includes("sensing")) {
        setSensing(sensing + 1)
      } else if (id[number].includes("feeling")) {
        setFeeling(feeling + 1)
      } else if (id[number].includes("thinking")) {
        setThinking(thinking + 1)
      }
      number = number + 1
    }
    
    //Reset ID array, reset agree & disagree whilst adding them to a final agree & disagree array, nextButton set to false.
    setFinalAgree(finalAgree.concat(agree))
    setFinalDisagree(finalDisagree.concat(disagree))
    setId([])
    setAgree([])
    setDisagree([])
    setNextButtonStatus(false)
    //Link to next statements
    if (currentPage === green) {
      setCurrentPage(blue)
    } else if (currentPage === blue) {
      setCurrentPage(orange)
    } else if (currentPage === orange) {
      setCurrentPage(red)
    }
    window.scrollTo(0,0)
  }


    return (
      <div className="App">
        {finalAgree.length !== 20 ? (
        <>
        <h3 id="basicInstruction">Select {5 - agree.length} {agree.length > 0 ? "more" : ""} {agree.length === 4 ? "statement" : "statements"} that {agree.length === 4 ? "is" : "are"} <span style={{color: '#007b60'}}>most</span> like you and {5 - disagree.length} {disagree.length > 0 ? "more" : ""} {disagree.length === 4 ? "statement" : "statements"} that {disagree.length === 4 ? "is" : "are"} <span style={{color: '#ed364c'}}>least</span> like you</h3>
        <Cards agreeClick={agreeClick} disagreeClick={disagreeClick} unclick={unclick} currentPage={currentPage} setCurrentPage={setCurrentPage} agree={agree} setAgree={setAgree} disagree={disagree} setDisagree={setDisagree}/>
        <Score currentPage={currentPage} nextButton={nextButton} id={id} agree={agree} disagree={disagree}/>
        </>
        )
        : (
          <>
        <Result finalAgree={finalAgree} finalDisagree={finalDisagree} extroversion={extroversion} introversion={introversion} sensing={sensing} intuition={intuition} thinking={thinking} feeling={feeling} judging={judging} perceiving={perceiving} />
        </>
        )}
      </div>
    )
}

export default Deck;
