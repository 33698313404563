import React, { Component } from "react";
import './popup.css';

export default class PopUpThree extends Component {
  handleClick = () => {
   this.props.toggle();
  };

render() {
  return (
   <div className="modal">
     <div className="modal_content">
     <span className="close" onClick={this.handleClick}>×</span>
     <p><span style={{fontWeight:"bold"}}>How To Save Your Statements as a PDF in Chrome</span>
     <ol>
  <li>Open the statements you’d like to keep.</li>
  <li>On the top left-hand corner, click File > Print.</li>
  <li>Alternatively, press Ctrl + P (Windows) or Cmd + P (Mac)</li>
  <li>Under the ‘Destination’ section, click Change... > Save as PDF</li>
  <li>Click the ‘Save’ button, pick the name and location for your PDF.</li>
</ol>
</p>

<p><span style={{fontWeight:"bold"}}>How To Save Your Statements as a PDF in Microsoft Edge</span>
     <ol>
  <li>Open the statements you’d like to keep.</li>
  <li>Press Ctrl + P (Windows) or Cmd + P (Mac).</li>
  <li>On the following dialog, under Printer, choose ‘Microsoft Print to PDF.’</li>
  <li>Click ‘Print’ and voila, you have saved your webpage to PDF.</li>
</ol>
</p>

<p><span style={{fontWeight:"bold"}}>How To Save Your Statements as a PDF in Safari</span>
     <ol>
  <li>Open the statements you’d like to keep.</li>
  <li>Press Ctrl + P (Windows) or Cmd + P (Mac).</li>
  <li>Click the drop-down arrow located on the bottom left.</li>
  <li>Choose ‘save as PDF’ and the location for the file.</li>
  <li>Hit’ Save,’ which should download your new PDF.</li>
</ol>
</p>
    </div>
   </div>
  );
 }
}