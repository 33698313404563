import React, { Component } from "react";
import './popup.css';

export default class PopUp extends Component {
  handleClick = () => {
   this.props.toggle();
  };

render() {
  return (
   <div className="modal">
     <div className="modal_content">
     <span className="close" onClick={this.handleClick}>×</span>
     <p>Taking each “most like me” statement in turn you should reflect on each by asking questions such as:
       <ul>
         <li>Give examples of real situations where this behaviour was demonstrated. How typical is this an example of my behaviour?</li>
         <li>What was the result of adopting this behaviour?</li>
         <li>How often does this benefit me?</li>
         <li>Does it have any implications for others, around me?</li>
         <li>Does this behaviour ever create a problem for me?</li>
       </ul>
</p>
    </div>
   </div>
  );
 }
}