import React, { Component } from 'react';
import './result.css';
import { Link } from "react-router-dom";
import ScrollToTop from './scrolltotop';
import PopUp from './sub-components/popup';
import PopUpTwo from './sub-components/popupTwo';
import PopUpThree from './sub-components/popupThree';


class Result extends Component {
    state = {
        seen: false,
        seenTwo: false,
        agreeGreen: false,
        agreeBlue: false,
        agreeOrange: false,
        agreeRed: false,
        disagreeGreen: false,
        disagreeBlue: false,
        disagreeOrange: false,
        disagreeRed: false,
    };

    //seen and seenTwo toggle on/off a how to button
    togglePop = () => {
        this.setState({
            seen: !this.state.seen
        });
    };

    togglePopTwo = () => {
        this.setState({
            seenTwo: !this.state.seenTwo
        });
    };

    togglePopThree = () => {
        this.setState({
            seenThree: !this.state.seenThree
        });
    };

    // this following function toggles open/closed the statements
    hideStatements = (selectedStatement) => {
        if (selectedStatement === "agreeGreen") {
            this.setState({
                agreeGreen: !this.state.agreeGreen
            })
        } else if (selectedStatement === "agreeBlue") {
            this.setState({
                agreeBlue: !this.state.agreeBlue
            })
        } else if (selectedStatement === "agreeOrange") {
            this.setState({
                agreeOrange: !this.state.agreeOrange
            })
        } else if (selectedStatement === "agreeRed") {
            this.setState({
                agreeRed: !this.state.agreeRed
            })
        } else if (selectedStatement === "disagreeGreen") {
            this.setState({
                disagreeGreen: !this.state.disagreeGreen
            })
        } else if (selectedStatement === "disagreeBlue") {
            this.setState({
                disagreeBlue: !this.state.disagreeBlue
            })
        } else if (selectedStatement === "disagreeOrange") {
            this.setState({
                disagreeOrange: !this.state.disagreeOrange
            })
        } else if (selectedStatement === "disagreeRed") {
            this.setState({
                disagreeRed: !this.state.disagreeRed
            })
        }
    }

    render() {

        return (
            // scrolltotop is an imported function that takes the page to the top, because I was using link from react-router-dom this wasn't happening
            // then the following code will only render if 20 statements have been selected, if not a button will appear asking the user to go back to the start
            // if one of the statements has more than its opposite then the matching statement describing the user will appear using a ternery operator
            // the selected statements are the shown to the user
            // this is repeated for all four agree statements and then another four for the disagree statements for the user to use with their coach
            <div class="results">
                <ScrollToTop />

                {this.props.finalAgree.length === 20 && this.props.finalDisagree.length === 20
                    ? <div>

                        <article id="mostLikeMe">
                            <div className="myStatements"><h2>Statements Describing Me Best</h2>
                                <h6 id="howToBtn" onClick={this.togglePop}>How to use these statements</h6></div>
                            {this.state.seen ? <PopUp toggle={this.togglePop} /> : null}

                            <div className="statementBox">
                                <h3 id="greenParagraph">How I See the World Around Me</h3>
                                <div>{this.props.introversion > this.props.extroversion
                                    ? <h5>I prefer to focus on how things work rather than focus on their impact.</h5>
                                    : <h5>I prefer to focus on impact rather than on how things work.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("agreeGreen")} style={{ textAlign: "center" }}>{this.state.agreeGreen ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.agreeGreen ? 'block' : 'none' }}>
                                    <li>{this.props.finalAgree[0]}</li>
                                    <li>{this.props.finalAgree[1]}</li>
                                    <li>{this.props.finalAgree[2]}</li>
                                    <li>{this.props.finalAgree[3]}</li>
                                    <li>{this.props.finalAgree[4]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="blueParagraph">How I Take on Information About the World Around Me</h3>
                                <div>{this.props.intuition > this.props.sensing
                                    ? <h5>I prefer to use intuition rather than hard data when taking information on board.</h5>
                                    : <h5>I prefer to work with hard data rather than intuition when taking information on board.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("agreeBlue")} style={{ textAlign: "center" }}>{this.state.agreeBlue ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.agreeBlue ? 'block' : 'none' }}>
                                    <li>{this.props.finalAgree[5]}</li>
                                    <li>{this.props.finalAgree[6]}</li>
                                    <li>{this.props.finalAgree[7]}</li>
                                    <li>{this.props.finalAgree[8]}</li>
                                    <li>{this.props.finalAgree[9]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="orangeParagraph">How I Process Information</h3>
                                <div>{this.props.feeling > this.props.thinking
                                    ? <h5>When processing information I take into account mine and others feelings.</h5>
                                    : <h5>When processing information I prefer to rely on logic.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("agreeOrange")} style={{ textAlign: "center" }}>{this.state.agreeOrange ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.agreeOrange ? 'block' : 'none' }}>
                                    <li>{this.props.finalAgree[10]}</li>
                                    <li>{this.props.finalAgree[11]}</li>
                                    <li>{this.props.finalAgree[12]}</li>
                                    <li>{this.props.finalAgree[13]}</li>
                                    <li>{this.props.finalAgree[14]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="redParagraph">How Important are the Decisions I Make About the World?</h3>
                                <div>{this.props.perceiving > this.props.judging
                                    ? <h5>I prefer to make quick decisions.</h5>
                                    : <h5>I prefer to leave decisions open.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("agreeRed")} style={{ textAlign: "center" }}>{this.state.agreeRed ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.agreeRed ? 'block' : 'none' }}>
                                    <li>{this.props.finalAgree[15]}</li>
                                    <li>{this.props.finalAgree[16]}</li>
                                    <li>{this.props.finalAgree[17]}</li>
                                    <li>{this.props.finalAgree[18]}</li>
                                    <li>{this.props.finalAgree[19]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>
                        </article>

                        <article id="leastLikeMe">
                            <div className="myStatements"><h2>Statements Describing Me Least</h2>
                                <h6 id="howToBtn" onClick={this.togglePopTwo}>How to use these statements</h6></div>
                            {this.state.seenTwo ? <PopUpTwo toggle={this.togglePopTwo} /> : null}
                            <div className="statementBox">
                                <h3 id="greenParagraph">How I See the World Around Me</h3>
                                <div>{this.props.extroversion > this.props.introversion
                                    ? <h5>I prefer to focus on impact rather than on how things work.</h5>
                                    : <h5>I prefer to focus on how things work rather than focus on their impact.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("disagreeGreen")} style={{ textAlign: "center" }}>{this.state.disagreeGreen ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.disagreeGreen ? 'block' : 'none' }}>
                                    <li>{this.props.finalDisagree[0]}</li>
                                    <li>{this.props.finalDisagree[1]}</li>
                                    <li>{this.props.finalDisagree[2]}</li>
                                    <li>{this.props.finalDisagree[3]}</li>
                                    <li>{this.props.finalDisagree[4]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="blueParagraph">How I Take On Information About the World Around Me</h3>
                                <div>{this.props.sensing > this.props.intuition
                                    ? <h5>I prefer to work with hard data rather than intuition when taking information on board.</h5>
                                    : <h5>I prefer to use intuition rather than hard data when taking information on board.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("disagreeBlue")} style={{ textAlign: "center" }}>{this.state.disagreeBlue ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.disagreeBlue ? 'block' : 'none' }}>
                                    <li>{this.props.finalDisagree[5]}</li>
                                    <li>{this.props.finalDisagree[6]}</li>
                                    <li>{this.props.finalDisagree[7]}</li>
                                    <li>{this.props.finalDisagree[8]}</li>
                                    <li>{this.props.finalDisagree[9]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="orangeParagraph">How I Process Information</h3>
                                <div>{this.props.thinking > this.props.feeling
                                    ? <h5>When processing information I prefer to rely on logic.</h5>
                                    : <h5>When processing information I take into account mine and others feelings.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("disagreeOrange")} style={{ textAlign: "center" }}>{this.state.disagreeOrange ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.disagreeOrange ? 'block' : 'none' }}>
                                    <li>{this.props.finalDisagree[10]}</li>
                                    <li>{this.props.finalDisagree[11]}</li>
                                    <li>{this.props.finalDisagree[12]}</li>
                                    <li>{this.props.finalDisagree[13]}</li>
                                    <li>{this.props.finalDisagree[14]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>

                            <div className="statementBox">
                                <h3 id="redParagraph">How Important are the Decisions I Make About the World</h3>
                                <div>{this.props.judging > this.props.perceiving
                                    ? <h5>I prefer to leave decisions open.</h5>
                                    : <h5>I prefer to make quick decisions.</h5>
                                }</div>
                                <h6 className="btn" onClick={() => this.hideStatements("disagreeRed")} style={{ textAlign: "center" }}>{this.state.disagreeRed ? 'Hide' : 'Show'} Statements</h6>
                                <ul className="statementList" style={{ display: this.state.disagreeRed ? 'block' : 'none' }}>
                                    <li>{this.props.finalDisagree[15]}</li>
                                    <li>{this.props.finalDisagree[16]}</li>
                                    <li>{this.props.finalDisagree[17]}</li>
                                    <li>{this.props.finalDisagree[18]}</li>
                                    <li>{this.props.finalDisagree[19]}</li>
                                </ul>
                                {/* <hr class="solid"></hr> */}
                            </div>
                    
                        </article>
                        
                        
                        {/* the below code uses the number of statements selected and gives the user their four letter personality type based on the Jungian model, below that is a link to read about their four letter type on an external website*/}
                        <article id="personalityType">
                                    <h2>Your personality type could be:</h2>
                        <div this shows the user id="card">
                            <h2 className="letters" id="green">{this.props.introversion > this.props.extroversion
                                ? <span>I</span>
                                : <span>E</span>
                            }</h2>
                            <h2 className="letters" id="blue">{this.props.intuition > this.props.sensing
                                ? <span>N</span>
                                : <span>S</span>
                            }</h2>
                            <h2 className="letters" id="orange">{this.props.feeling > this.props.thinking
                                ? <span>F</span>
                                : <span>T</span>
                            }</h2>
                            <h2 className="letters" id="red">{this.props.perceiving > this.props.judging
                                ? <span>P</span>
                                : <span>J</span>
                            }</h2>
                        </div>
                        <button id="PTbutton"><a id="PTanchor" href="https://aqrinternational.co.uk/16-personality-types" target="_blank" rel="noopener noreferrer">Learn more about the 16 Personality Types</a></button>

                        <div>
                                <button id="PTbutton" onClick={this.togglePopThree}><span style={{color:"white"}}>Save as PDF</span></button></div>
                            {this.state.seenThree ? <PopUpThree toggle={this.togglePopThree} /> : null}

                        </article>
                    </div>
                    : <div><br></br><Link id="backButton" to="/"><span>Click here to complete the card sort</span></Link></div>}
            </div>
        )
    }
}

export default Result;