import { Link } from "react-router-dom"
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import {useSelector} from 'react-redux'
import "./Dashboard.css"
import CSlogo from "./logo512.png"

function Dashboard() {

    const {user} = useSelector((state) => state.auth)

    return (
        <div>

            <img id="CSlogo" alt="card sort logo" src={CSlogo}></img>
            {user ? (<>
            
                <div id="start">
                <Accordion atomic={true}>
                    <AccordionItem title="Instructions for use">
                        <p style={{ padding: '18px' }}>
                        This exercise is designed to identify some of your core behaviours. It is based on a Jungian model of personality. It uses 64 statements of behaviour split across 4 pages.<br></br><br></br>
                Your task is to read each statement and, with your first reaction, decide whether you feel it describes you most ✔ or least ✘. On each page, you should choose 5 statements you feel are most like you and 5 that are least like you on each page. In total, you can only choose 10 statements on each page. If you change your mind about a selection, choose the statement again to unselect it. Once you get through the page, click ‘next’.
            </p>
                    </AccordionItem>
                    <AccordionItem title="Purpose">
                        <p style={{ padding: '18px' }}>
                        This exercise is a structured approach to identify core behaviours, which you believe are good descriptors of you. This provides a platform from which you can explore these perceptions and what might be their implications for performance and wellbeing.<br></br><br></br> It works particularly well with the Mental Toughness concept and the <a href="https://aqrinternational.co.uk/mtq48-mental-toughness-questionnaire" target="_blank" rel="noreferrer">MTQ assessment</a> where users can dig deeper to help understand why you might adopt certain behaviours.
            </p>
                    </AccordionItem>
                    <AccordionItem title="On completion - Using the output">
                        <p style={{ padding: '18px' }}>
                        You will have selected 20 statements which you have identified as good descriptors of a behaviour that you adopt. You will also have selected 20 statements that you feel are those which mostly do not apply to you.<br></br>
                <br></br>This provides a starting point for a valuable process in personal development. Self Awareness (about my behaviours) > Reflection > Implications and Action > new Habits.<br></br>
                <br></br>It is useful to work with a trained person – a qualified coach or mentor – who will help you to reflect on the implications of these statements in your life and in your work.
            </p>
                    </AccordionItem>
                </Accordion>
                {/* Start button below uses Link imported from react-router-dom to take the user to the deck component */}
                <Link className="btn btn-block" to="/deck"><span>Start</span></Link>
            </div>

            </>) : (
                <>
                
                <div id="start">
                <h2>Welcome to the Jungian Card Sort Exercise</h2>
                <h3>64 starting points for great coaching conversations</h3>
                <Link className="btn btn-block" to="/login">Login</Link>
            </div>

                </>
            )}
        </div>
    )
}

export default Dashboard











// function Dashboard() {
//     return (
//         <div>
//             Dashboard
//         </div>
//     )
// }

// export default Dashboard