import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//this is shown throughout the other components and fixed a bug where the new page would be where the user last was. It is adapted from a solution found online. 

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}