// Statements for Introversion(I) or Extroversion(E)
let green = [
    {statement : "I don't mind working on a single project over a period of time.", type : "introversion", ticked: false, agree: false, id: "introversion0"},
    {statement : "I find it is important to get the detail right when I'm working.", type : "introversion", ticked: false, agree: false, id: "introversion1"},
    {statement : "I am happy to work by myself much of the time.", type : "introversion", ticked: false, agree: false, id: "introversion2"},
    {statement : "I tend to be interested in the idea behind the task rather than the task itself.", type : "introversion", ticked: false, agree: false, id: "introversion3"},
    {statement : "I prefer to be careful - working things out before I start 'doing'.", type : "introversion", ticked: false, agree: false, id: "introversion4"},
    {statement : "I prefer peace and quiet when I'm working.", type : "introversion", ticked: false, agree: false, id: "introversion5"},
    {statement : "I'm generally not good at remembering names.", type : "introversion", ticked: false, agree: false, id: "introversion6"},
    {statement : "I find that sometimes I have problems communicating with others.", type : "introversion", ticked: false, agree: false, id: "introversion7"},
    {statement : "I often find I act quickly and miss something quite important.", type : "extroversion", ticked: false, agree: false, id: "extroversion0"},
    {statement : "I tend to lose patience if I'm doing something that takes a long time.", type : "extroversion", ticked: false, agree: false, id: "extroversion1"},
    {statement : "People usually see me as chatty and outgoing.", type : "extroversion", ticked: false, agree: false, id: "extroversion2"},
    {statement : "I am usually seen as friendly when meeting people.", type : "extroversion", ticked: false, agree: false, id: "extroversion3"},
    {statement : "I tend to be results driven, and usually want to get the job done.", type : "extroversion", ticked: false, agree: false, id: "extroversion4"},
    {statement : "I like to be involved in a wide range of activities.", type : "extroversion", ticked: false, agree: false, id: "extroversion5"},
    {statement : "I prefer to be with people most of the time.", type : "extroversion", ticked: false, agree: false, id: "extroversion6"},
    {statement : "I tend to work quickly preferring simple ways of doing things.", type : "extroversion", ticked: false, agree: false, id: "extroversion7"}
]

// Statements for Intuition(N) or Sensing(S)
let blue = [
    {statement : "I like learning about new things even if I don't have to use what I learn.", type : "intuition", ticked: false, agree: false, id: "intuition0"},
    {statement : "I tend to work things out quickly.", type : "intuition", ticked: false, agree: false, id: "intuition1"},
    {statement : "I enjoy problem solving, especially new challenges.", type : "intuition", ticked: false, agree: false, id: "intuition2"},
    {statement : "I tend to follow my gut instinct and ideas.", type : "intuition", ticked: false, agree: false, id: "intuition3"},
    {statement : "I don't like doing the same thing over and over again.", type : "intuition", ticked: false, agree: false, id: "intuition4"},
    {statement : "I prefer to work in ‘short, sharp bursts.'", type : "intuition", ticked: false, agree: false, id: "intuition5"},
    {statement : "I find that I often make mistakes because I have the detail wrong.", type : "intuition", ticked: false, agree: false, id: "intuition6"},
    {statement : "I like to get on with it, not waste time by looking at unnecessary detail.", type : "intuition", ticked: false, agree: false, id: "intuition7"},
    {statement : "I tend to have a good idea of how long a task will take to be completed.", type : "sensing", ticked: false, agree: false, id: "sensing0"},
    {statement : "I like dealing with problems where I know how to solve them.", type : "sensing", ticked: false, agree: false, id: "sensing1"},
    {statement : "I prefer using skills I've already got - I'm less interested in developing new skills.", type : "sensing", ticked: false, agree: false, id: "sensing2"},
    {statement : "I prefer not to rely on gut instincts when doing things.", type : "sensing", ticked: false, agree: false, id: "sensing3"},
    {statement : "I generally get my facts right.", type : "sensing", ticked: false, agree: false, id: "sensing4"},
    {statement : "I prefer to approach my tasks in a systematic way.", type : "sensing", ticked: false, agree: false, id: "sensing5"},
    {statement : "I tend to work in a structured and steady manner.", type : "sensing", ticked: false, agree: false, id: "sensing6"},
    {statement : "Generally I am good at precise and detailed work.", type : "sensing", ticked: false, agree: false, id: "sensing7"}
]

// Statements for Thinking(T) or Feeling(F)
let orange = [
    {statement : "I frequently take other peoples feelings into account when making decisions.", type : "feeling", ticked: false, agree: false, id: "feeling0"},
    {statement : "I like making people happy in any way I can.", type : "feeling", ticked: false, agree: false, id: "feeling1"},
    {statement : "I would describe myself as a people person.", type : "feeling", ticked: false, agree: false, id: "feeling2"},
    {statement : "I am seen as a compassionate person.", type : "feeling", ticked: false, agree: false, id: "feeling3"},
    {statement : "I don't like having to pass on negative comments or bad news.", type : "feeling", ticked: false, agree: false, id: "feeling4"},
    {statement : "I need a pat on the back every now and again.", type : "feeling", ticked: false, agree: false, id: "feeling5"},
    {statement : "I'm sensitive to other peoples feelings.", type : "feeling", ticked: false, agree: false, id: "feeling6"},
    {statement : "I prefer it when things go well, disagreements can be disruptive.", type : "feeling", ticked: false, agree: false, id: "feeling7"},
    {statement : "I am better at understanding people’s thoughts than their feelings.", type : "thinking", ticked: false, agree: false, id: "thinking0"},
    {statement : "I am decisive - and I stick with my decisions.", type : "thinking", ticked: false, agree: false, id: "thinking1"},
    {statement : "I can upset people without realising it.", type : "thinking", ticked: false, agree: false, id: "thinking2"},
    {statement : "I can sometimes be seen as inconsiderate when working with others.", type : "thinking", ticked: false, agree: false, id: "thinking3"},
    {statement : "If I’ve done a good job, it’s reasonable that I am recognised for that.", type : "thinking", ticked: false, agree: false, id: "thinking4"},
    {statement : "I don't mind having to tell others off if it is needed.", type : "thinking", ticked: false, agree: false, id: "thinking5"},
    {statement : "I rarely like having to cope with other people's feelings.", type : "thinking", ticked: false, agree: false, id: "thinking6"},
    {statement : "I can get things done without having to keep other people happy.", type : "thinking", ticked: false, agree: false, id: "thinking7"}
]

// Statements for Perceiving(P) or Judging(J)
let red = [
    {statement : "I sometimes put off doing jobs I don’t like.", type : "perceiving", ticked: false, id: "perceiving0"},
    {statement : "I can deal with a lot of uncertainty.", type : "perceiving", ticked: false, id: "perceiving1"},
    {statement : "I'm happy to keep things open as work rolls out, so that I can respond to what happens.", type : "perceiving", ticked: false, id: "perceiving2"},
    {statement : "I prefer making a good decision rather than a quick decision.", type : "perceiving", ticked: false, id: "perceiving3"},
    {statement : "I tend to deal with change effectively.", type : "perceiving", ticked: false, id: "perceiving4"},
    {statement : "I am interested in finding out new ways of doing things.", type : "perceiving", ticked: false, id: "perceiving5"},
    {statement : "I tend to like a lot of information on a new job before starting it.", type : "perceiving", ticked: false, id: "perceiving6"},
    {statement : "I tend to take too much on, and often don't finish my work.", type : "perceiving", ticked: false, id: "perceiving7"},
    {statement : "I can miss other priorities when focused on the matter at hand.", type : "judging", ticked: false, id: "judging0"},
    {statement : "Making a quick decision is more important to me than making a perfect decision.", type : "judging", ticked: false, id: "judging1"},
    {statement : "I usually want to know where I stand when asked to do something.", type : "judging", ticked: false, id: "judging2"},
    {statement : "I usually only need the minimum of information to get going on something.", type : "judging", ticked: false, id: "judging3"},
    {statement : "I make snap decisions a lot of the time.", type : "judging", ticked: false, id: "judging4"},
    {statement : "I prefer it when things are completely finished.", type : "judging", ticked: false, id: "judging5"},
    {statement : "I like to keep at what I've been given to do - even if someone wants me to do something they think is more important.", type : "judging", ticked: false, id: "judging6"},
    {statement : "I like being able to plan my work out, then follow the plan.", type : "judging", ticked: false, id: "judging7"}
]

const shuffle = (array) => {

    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;

};

shuffle(green);
shuffle(blue);
shuffle(orange);
shuffle(red);

export { green, blue, orange, red }